import React, { Component } from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import Skeleton from "react-loading-skeleton";
import Loading from "../Loading/loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  UncontrolledDropdown,
  // DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
  Popover,
} from "reactstrap";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions/actions";
import { logoutUser } from "./../../api/";

// import ToggleFullscreen from "../Common/ToggleFullscreen";
import HeaderRun from "./Header.run";
import { Link } from "react-router-dom";

import { IS_PRODUCTION } from "../../config";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownUserMenu: false,
      dataUser: false,
      isLoading: false,
    };
  }

  componentDidMount = async () => {
    await HeaderRun();
    const isLogin = Cookies.get("isLogin");
    const token = Cookies.get("token");
    const version = Cookies.get("version");
    if (version !== process.env.REACT_APP_COOKIES_VERSION) {
      Cookies.remove("menu");
      Cookies.remove("pad");
      Cookies.remove("isLogin");
      Cookies.remove("userData");
      Cookies.remove("token");
      Cookies.remove("refresh-token");
      Cookies.remove("version");
      window.location.href = "/";
    } else {
      if (isLogin && token) {
        const userData = JSON.parse(Cookies.get("userData"));
        this.setState({
          dataUser: userData,
        });
      } else {
        Cookies.remove("menu");
        Cookies.remove("pad");
        Cookies.remove("isLogin");
        Cookies.remove("userData");
        Cookies.remove("token");
        Cookies.remove("refresh-token");
        Cookies.remove("version");
        window.location.href = "/";
      }
    }
  };

  handleLogout = async () => {
    this.setState({
      isLoading: true,
    });
    const { result, error } = await logoutUser();
    if (result) {
      if (result && result.data === "Logout Successfully") {
        Cookies.remove("menu");
        Cookies.remove("pad");
        Cookies.remove("isLogin");
        Cookies.remove("userData");
        Cookies.remove("token");
        Cookies.remove("refresh-token");
        Cookies.remove("version");
        window.location.href = "/";
      } else {
        this.setState(
          {
            isLoading: false,
          },
          () => {
            toast(result.error.message, {
              type: "error",
              position: "top-center",
              pauseOnHover: false,
              pauseOnFocusLoss: false,
              autoClose: 2000,
            });
          }
        );
      }
    } else {
      this.setState(
        {
          isLoading: false,
        },
        () => {
          toast(error.message, {
            type: "error",
            position: "top-center",
            pauseOnHover: false,
            pauseOnFocusLoss: false,
            autoClose: 2000,
          });
        }
      );
    }
  };

  toggleUserblock = (e) => {
    e.preventDefault();
    this.props.actions.toggleSetting("showUserBlock");
  };

  toggleOffsidebar = (e) => {
    e.preventDefault();
    this.props.actions.toggleSetting("offsidebarOpen");
  };

  toggleCollapsed = (e) => {
    e.preventDefault();
    this.props.actions.toggleSetting("isCollapsed");
    this.resize();
  };

  toggleAside = (e) => {
    e.preventDefault();
    this.props.actions.toggleSetting("asideToggled");
  };

  resize() {
    var evt = document.createEvent("UIEvents");
    evt.initUIEvent("resize", true, false, window, 0);
    window.dispatchEvent(evt);
  }

  toggleUser = () => {
    this.setState({
      dropdownUserMenu: !this.state.dropdownUserMenu,
    });
  };
  render() {
    const { dataUser, isLoading } = this.state;
    const isNadine = window.location.pathname.split("/")[1] === "marketing";
    return (
      <header className="topnavbar-wrapper">
        <Loading isLoading={isLoading} />
        <ToastContainer />
        {/* START Top Navbar */}
        <nav className="navbar topnavbar">
          {/* START navbar header */}
          <div
            className="navbar-header"
            style={{ backgroundColor: IS_PRODUCTION ? "#2cbecb" : "#982597" }}
          >
            <a className="navbar-brand" href="#/">
              <div className="brand-logo">
                <img
                style={{width:'30%'}}
                  className="img-fluid"
                  // src="img/logo-eshop.png"
                  // src="https://edot.id/assets/img/hero-navbar-img.svg"
                  src="img/edot_logo.png"
                  width="50%"
                  alt="App Logo"
                />
              </div>
              <div className="brand-logo-collapsed">
                <img
                  className="img-fluid"
                  src="img/logo-eshop-single.png"
                  alt="App Logo"
                />
              </div>
            </a>
          </div>

          <ul className="navbar-nav flex-row">
            <li className="nav-item">
              <a
                href=""
                className="nav-link d-none d-md-block d-lg-block d-xl-block"
                onClick={this.toggleCollapsed}
              >
                <em className="fas fa-bars"></em>
              </a>

              <a
                href=""
                className="nav-link sidebar-toggle d-md-none"
                onClick={this.toggleAside}
              >
                <em className="fas fa-bars"></em>
              </a>
            </li>
          </ul>

          <ul className="navbar-nav top_headertitle mx-auto flex-row">
            {IS_PRODUCTION && (
              <li className="nav-item" style={{ fontWeight: "bold", fontSize: "26px" }}>
                eDOT DISTRIBUTION SYSTEM
              </li>
            )}
            {!IS_PRODUCTION && (
              <li className="nav-item" style={{ fontWeight: "bold", color: "#eb008b", fontSize: "26px" }}>
                STAGING - eDOT DISTRIBUTION SYSTEM
              </li>
            )}
            {/* <li className="nav-item" style={{ fontWeight: "bold", color: "#eb008b", fontSize: "22px" }}>
              {IS_PRODUCTION
                ? "EDOT DISTRIBUTION SYSTEM"
                : "STAGING - EDOT DISTRIBUTION SYSTEM"}
            </li> */}
          </ul>
          <ul className="navbar-nav flex-row">
            <UncontrolledDropdown nav inNavbar className="dropdown-list">
              {/* <DropdownToggle nav className="dropdown-toggle-nocaret">
                <em className="icon-bell"></em>
                <span className="badge badge-danger">11</span>
              </DropdownToggle> */}

              <DropdownMenu
                right
                className="dropdown-menu-right animated flipInX"
              >
                <DropdownItem>
                  {/* START list group */}
                  <ListGroup>
                    <ListGroupItem
                      action
                      tag="a"
                      href=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <div className="media">
                        <div className="align-self-start mr-2">
                          <em className="fab fa-twitter fa-2x text-info"></em>
                        </div>
                        <div className="media-body">
                          <p className="m-0">New followers</p>
                          <p className="m-0 text-muted text-sm">
                            1 new follower
                          </p>
                        </div>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem
                      action
                      tag="a"
                      href=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <div className="media">
                        <div className="align-self-start mr-2">
                          <em className="fa fa-envelope fa-2x text-warning"></em>
                        </div>
                        <div className="media-body">
                          <p className="m-0">New e-mails</p>
                          <p className="m-0 text-muted text-sm">
                            You have 10 new emails
                          </p>
                        </div>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem
                      action
                      tag="a"
                      href=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <div className="media">
                        <div className="align-self-start mr-2">
                          <em className="fa fa-tasks fa-2x text-success"></em>
                        </div>
                        <div className="media-body">
                          <p className="m-0">Pending Tasks</p>
                          <p className="m-0 text-muted text-sm">
                            11 pending task
                          </p>
                        </div>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem
                      action
                      tag="a"
                      href=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <span className="d-flex align-items-center">
                        <span className="text-sm">More notifications</span>
                        <span className="badge badge-danger ml-auto">14</span>
                      </span>
                    </ListGroupItem>
                  </ListGroup>
                  {/* END list group */}
                </DropdownItem>
              </DropdownMenu>
              {/* END Dropdown menu */}
            </UncontrolledDropdown>
            <li className="nav-item d-md-block">
              <a
                className="nav-link"
                onClick={this.toggleUser}
                id="Popover-User"
              >
                <em className="icon-user"></em>
              </a>

              <Popover
                placement="bottom"
                isOpen={this.state.dropdownUserMenu}
                target={"Popover-User"}
                toggle={this.toggleUser}
              >
                <div
                  className="popover-header bg-white text-sm"
                  style={{ minWidth: "250px" }}
                >
                  <div className="d-flex align-items-center">
                    <img
                      className="mr-3 rounded-circle thumb48"
                      src="img/user/pp.png"
                      alt="Avatar"
                    />
                    <p className="m-0">
                      {dataUser ? (
                        <b>{dataUser.fullname}</b>
                      ) : (
                        <Skeleton width={75} />
                      )}
                      <br />
                      {dataUser ? (
                        <b>{dataUser.email}</b>
                      ) : (
                        <Skeleton width={75} />
                      )}
                    </p>
                  </div>
                </div>
                <div className="popover-body userMenu p-0">
                  <Link className="nav-link profile" to="/profile">
                    <em className="fas fa-user mr-2"></em> Profile
                  </Link>
                  <button
                    className="nav-link profile"
                    onClick={this.handleLogout}
                  >
                    <em className="icon-logout mr-2"></em> Logout
                  </button>
                </div>
              </Popover>
            </li>
          </ul>

          <form className="navbar-form" role="search" action="search.html">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Type and hit enter ..."
              />
              <div
                className="fa fa-times navbar-form-close"
                data-search-dismiss=""
              ></div>
            </div>
            <button className="d-none" type="submit">
              Submit
            </button>
          </form>
        </nav>
      </header>
    );
  }
}

Header.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object,
};

const mapStateToProps = (state) => ({ settings: state.settings });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
